import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useFormContext } from "react-hook-form";
import {
  TextField,
  Title,
  FileUploaderInput,
  Banner,
  Select,
} from "components";
import { LockedIcon, PdfIcon, AttachmentIcon } from "assets/svg";
import { useGlobalData } from "hooks/api";
import { Container, Text, FieldContainer, StandbyMessage } from "./styles";
import { usePostAnyDocs } from "features/connection-form/api/post-AnyDocs";
import { EnergyBillAnalysis } from "context";
import {
  POWER_COMPANIES_WITH_RULES,
  PF,
  PJ,
  POWER_COMPANIES_WITH_PROMPTS,
  Classification,
} from "./constants";

export type ConsumptionState = "MG" | "MT" | "MS" | "RJ" | "SP" | "GO" | "PE";

interface PDFUploadProps {
  disabled?: boolean;
  generateConcessionaireOptions: any;
}

const validateResult = (data: EnergyBillAnalysis): boolean => {
  if (data.classificacao === Classification.RURAL) {
    return true;
  }

  if (!data.numcliente || !data.numinstalacao || Number(data.valorkwh) === 0)
    return false;

  return true;
};

const fillAddressBasedOnAccountType = (
  accountType: string,
  data: EnergyBillAnalysis,
  stateOfConsumption: string
) => {
  if (!data.endereco || ["GO", "MT"].includes(stateOfConsumption)) return {};

  const prefix = accountType === PF ? "" : "emp_";
  const { cep, numero, complemento, bairro, estado, cidade, rua } =
    data.endereco;
  return {
    [`${prefix}cep`]: cep,
    [`${prefix}numero`]: numero,
    [`${prefix}complemento`]: complemento,
    [`${prefix}bairro`]: bairro,
    [`${prefix}uf`]: estado,
    [`${prefix}cidade`]: cidade,
    [`${prefix}endereco`]: rua,
  };
};

const PDFUpload: React.FC<PDFUploadProps> = ({
  disabled,
  generateConcessionaireOptions,
}) => {
  const [concessionaireOptions, setConcessionaireOptions] = useState([]);
    useState<boolean | null>();
  const { watch, reset, getValues, resetField } = useFormContext();
  const {
    customer,
    globalEnergyBill,
    accountType,
    setIsLoading,
    setFormState,
    setAverageConsumption,
    setBillElegibility,
    setGlobalEnergyBill,
    setCurrentStep,
    setEligibilityErrors,
    setCustomerIdFromEnergyBill,
    setEnergyBillAnalysisResult,
    setNeedManualAnalysis
  } = useGlobalData();
  const eligibility = customer?.elegibilidade;
  const [pdfField, passwordField, powerCompany] = watch([
    "caminhoarquivo",
    "senhapdf",
    "concessionaria",
  ]);
  const stateOfConsumption: ConsumptionState = watch("ufconsumo");
  const { mutate: onAnalyzingEnergyBill } = usePostAnyDocs();

  useEffect(() => {
    if (!stateOfConsumption) return;

    const options = generateConcessionaireOptions(stateOfConsumption);
    setConcessionaireOptions(options);
  }, [generateConcessionaireOptions, stateOfConsumption]);

  const handleSuccess = (data: EnergyBillAnalysis) => {
    const resultIsValid = validateResult(data);
    const elegibility = Boolean(data.elegibilidade.elegibility);
    const isValidPowerCompany = POWER_COMPANIES_WITH_RULES.includes(
      `${powerCompany}_${stateOfConsumption}`
    );

    if (isValidPowerCompany && !elegibility) {
      setIsLoading(false);
      setEligibilityErrors(data.elegibilidade.messages);
      return setFormState("error");
    }

    const energyBillAddress = fillAddressBasedOnAccountType(
      accountType,
      data,
      stateOfConsumption
    );

    if (!resultIsValid) {
      setIsLoading(false);
      resetField("caminhoarquivo");
      return toast.error("Documento inválido! Por favor, tente novamente.", {
        position: "bottom-center",
        duration: 10000,
      });
    }

    reset({
      ...getValues(),
      ...data,
      ...energyBillAddress,
      consumomedio: Number(data.consumomedio),
      razao: accountType === PJ ? data.nome : undefined,
    });

    setEnergyBillAnalysisResult(data);
    setAverageConsumption(data?.consumomedio);
    setCustomerIdFromEnergyBill(data?.cpfCnpj);
    setBillElegibility(elegibility);
    setGlobalEnergyBill(pdfField);
    setNeedManualAnalysis(data.needManualAnalysis);

    setIsLoading(false);
    toast.success("Documento analisado com sucesso!", {
      position: "bottom-center",
    });
    setCurrentStep((prevState) => prevState + 1);
  };

  const handleError = ({ response: errorResponse }: any) => {
    const customErrorMessage =
      errorResponse?.data?.error?.info ??
      "Erro ao processar o arquivo. Certifique-se de que anexou um documento válido e legível e/ou caso for protegido, de que a senha está correta. Tente novamente!";

    toast.error(customErrorMessage, {
      duration: 10000,
    });
    setIsLoading(false);
    resetField("caminhoarquivo");
  };

  useEffect(() => {
    if (
      !pdfField ||
      !powerCompany.length ||
      !POWER_COMPANIES_WITH_PROMPTS.includes(
        `${powerCompany}_${stateOfConsumption}`
      ) ||
      pdfField === globalEnergyBill
    )
      return;

    const base64Parts = pdfField.split("base64,");
    const data = base64Parts[1];

    if (!data) return;

    setIsLoading(true);

    onAnalyzingEnergyBill(
      {
        type: "energyBill",
        file: data,
        powerCompany: powerCompany,
        password: passwordField,
        accountType,
        stateConsumption: stateOfConsumption,
      },
      {
        onSuccess: handleSuccess,
        onError: handleError,
      }
    );
  }, [pdfField, powerCompany]);

  const isStandByPowerCompany =
    stateOfConsumption === "SP" && powerCompany === "EDP";

  return (
    <>
      <FieldContainer>
        {isStandByPowerCompany && (
          <StandbyMessage>Conexão em stand by</StandbyMessage>
        )}

        <Text>Distribuidora de energia:</Text>
        <Select
          name="concessionaria"
          label="Selecione a sua distribuidora de energia"
          options={concessionaireOptions}
          required
          disabled={eligibility}
        />
      </FieldContainer>
      <FieldContainer>
        <Text>Digite a senha do arquivo caso seja protegido:</Text>
        <TextField
          name="senhapdf"
          label="Senha do arquivo (Opcional)"
          startAdornment={<LockedIcon />}
          disabled={disabled}
        />
      </FieldContainer>
      <FieldContainer>
        <Text>Envie-nos o documento da sua última conta de luz:</Text>
        <FileUploaderInput
          description="Anexar conta de luz"
          name="caminhoarquivo"
          icon={<PdfIcon />}
          accept="image/*,.pdf"
          disabled={disabled || isStandByPowerCompany}
        />
      </FieldContainer>
    </>
  );
};

const UploadEnergyBillForm = ({
  generateOptions,
}: {
  generateOptions: any;
}) => {
  const { customer, isLoading } = useGlobalData();
  const disableForm = customer?.elegibilidade;

  return (
    <Container>
      <Title>
        Agora precisamos que você nos fale qual é a empresa que lhe fornece
        energia e anexe sua conta de energia.
      </Title>

      <Banner
        icon={<AttachmentIcon />}
        description="Sua conta de energia será processada por uma Inteligência Artificial. Anexe-a e a IA extrairá os dados automaticamente, preenchendo o formulário para você. Rápido, seguro e eficiente."
      />

      <PDFUpload
        disabled={disableForm || isLoading}
        generateConcessionaireOptions={generateOptions}
      />
    </Container>
  );
};

export { UploadEnergyBillForm };
